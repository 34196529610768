import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import globalEmitter, { emits } from '../../../emitter/global.emitter';
import { setUser } from '../../../redux/user.slice';
import { setItem } from '../../../helpers/cookie.helper';
import TokensEnum from '../../../enums/tokens.enum';
import { setActivationUser } from '../../../redux/activationUser.slice';
import ModalsEnum from '../../../enums/modals.enum';

export default function UserWrapper() {
  const dispatch = useDispatch();
  const router = useRouter();

  const onUserAuth = useCallback(
    ({
      user,
      state,
      freeSpin,
      accessToken,
      refreshToken,
      query,
      withoutRedirect,
    }) => {
      try {
        if (accessToken && refreshToken && user) {
          // TODO GTAG event
          dispatch(setUser({ ...user, ...{ state } }));

          if (window.gtag) {
            window.gtag('event', 'login', {
              user_id: user.id,
            });
          }

          if (window?.OneSignal && user?.id) {
            window.OneSignal.setExternalUserId(user.id);
          }

          if (window?.Tawk_API) {
            window.Tawk_API.setAttributes(
              {
                phone: user.phone,
              },
              (error) => {
                console.log('TAWK ERROR', error);
              }
            );
          }

          setItem(TokensEnum.SLOT_TOKEN, accessToken, true);

          if (!withoutRedirect) {
            if (query?.from) {
              router.push(query.from, query.from);
            } else {
              router.push('/', '/');
            }
          }

          if (freeSpin) {
            setTimeout(() => {
              globalEmitter.emit(emits.MODAL, {
                id: ModalsEnum.FREE_SPINS,
                data: freeSpin,
              });
            }, 7000);
          }
        } else if (user?.state === 3) {
          dispatch(setActivationUser(user));
          router.push(
            `/activate?token=${user.token}`,
            `/activate?token=${user.token}`
          );
        }
      } catch (e) {
        globalEmitter.emit(emits.ALERT, {
          type: 'error',
          anchor: 'bottom',
          message: 'Something went wrong please try later',
        });
      }
    },
    []
  );

  useEffect(() => {
    globalEmitter.on(emits.USER_AUTH, onUserAuth);

    return () => {
      globalEmitter.removeListener(emits.ALERT, onUserAuth);
    };
  }, []);

  return <></>;
}
