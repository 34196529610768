import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  email: null,
  state: null,
  userName: null,
  firstName: null,
  lastName: null,
  birthDate: null,
  phone: null,
  balance: 0,
  operatorID: null,
  medias: null,
  country: null,
  city: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const {
        id,
        userName,
        firstName,
        lastName,
        birthDate,
        phone,
        balance,
        operatorID,
        medias,
        email,
        country,
        city,
      } = action.payload;

      const userState = action.payload.state;

      state.id = id;
      state.state = userState;
      state.email = email;
      state.userName = userName;
      state.firstName = firstName;
      state.lastName = lastName;
      state.birthDate = birthDate;
      state.phone = phone;
      state.balance = balance;
      state.operatorID = operatorID;
      state.medias = medias;
      state.country = country;
      state.city = city;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    logout: () => initialState,
  },
});

export const { setUser, setBalance, logout } = userSlice.actions;

export default userSlice.reducer;
