import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';

const BASE = '/Campaign';

export async function affiliate_req(token) {
  return parseResponse(axiosInstanceV1.get(`${BASE}/Affiliate?token=${token}`));
}

export async function teaseTracker_req({ partner_id, widget_id, teaser_id }) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/TeaseTracker?partner_id=${partner_id}&widget_id=${widget_id}&teaser_id=${teaser_id}`
    )
  );
}

export async function getAffiliate_req() {
  return parseResponse(axiosInstanceV1.get('/GetAffiliate', getReqConfig({})));
}
