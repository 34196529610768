import {
  axiosInstanceNextV1,
  axiosInstanceV1,
  getReqConfig,
} from './axiosInstance';
import { parseResponse } from './helper';

const BASE = '/User';

export async function getUser_req(cookies = {}) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/Get`, getReqConfig(cookies))
  );
}

export function resetPassword_req(phone) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/ResetPassword`, {
      phone,
    })
  );
}

export function verifyPasswordReset_req(token, code) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/VerifyPasswordReset`, {
      token,
      code,
    })
  );
}

export function resend_req(token) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/ResentCode`, { token }, getReqConfig())
  );
}

export function verify_req() {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/Verify`, {}, getReqConfig())
  );
}

export function changePassword_req({
  oldPassword,
  newPassword,
  confirmPassword,
}) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/ChangePassword`,
      {
        oldPassword,
        newPassword,
        confirmPassword,
      },
      getReqConfig()
    )
  );
}

export function addToSearchHistory_req(cookies = {}, gameID) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/SearchPlay`,
      { gameID },
      getReqConfig(cookies)
    )
  );
}

export function getAvailableWithdraw_req(cookies = {}) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/GetAvailableWithdraw`, getReqConfig(cookies))
  );
}

export function addFavoriteGame_req(gameID) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/AddFavoriteGame`, { gameID }, getReqConfig())
  );
}

export function deleteFavoriteGame_req(gameID) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/DeleteFavoriteGame`,
      { gameID },
      getReqConfig()
    )
  );
}

export function updateUser_req(data) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/UpdateUser`, data, getReqConfig())
  );
}

export function getTransactions_req(type = null, page = 1, viewCount = 10) {
  return parseResponse(
    axiosInstanceV1.get(
      `/DepositWithdraw?type=${type}&page=${page}&viewCount=${viewCount}`,
      getReqConfig()
    )
  );
}

export function getBetWinTransactions_req(page = 1) {
  return parseResponse(
    axiosInstanceV1.get(`/BetWin?page=${page}`, getReqConfig())
  );
}
