import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';
import LocalesEnum from '../enums/locales.enum';

const BASE = '/Payment';

export async function getOperators_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${locale}/Operators`, getReqConfig(cookies))
  );
}

export async function deposit_req(operatorID, amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Deposit`,
      {
        operatorID,
        amount,
      },
      getReqConfig({})
    )
  );
}

export async function withdraw_req(operatorID, amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Withdraw`,
      {
        operatorID,
        amount,
      },
      getReqConfig({})
    )
  );
}

export async function getBankList_req(cookies = {}) {
  return parseResponse(axiosInstanceV1.get('/PayStackBanks'));
}

export async function withdrawWithPaystack_req(
  cookies = {},
  BankCode,
  AccountNumber,
  Amount
) {
  return parseResponse(
    axiosInstanceV1.post(
      '/payment/withdrawps',
      {
        BankCode,
        AccountNumber,
        Amount,
        OperatorID: 6,
      },
      getReqConfig(cookies)
    )
  );
}

export async function getDepositTexts_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${locale}/DepositHeader`, getReqConfig(cookies))
  );
}
